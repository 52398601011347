$actionColor: rgb(68, 48, 73);
$attributeKeyColor: rgb(109, 116, 49);
$attributeColor: rgb(44, 100, 39);

// All nodes
.react-flow__handle {
    width: 10px;
    height: 10px;
}

.react-flow__handle-left {
  left: -8px;
}

.react-flow__handle-right {
  right: -8px;
}

// Output attributes
.react-flow__node-attribute {
  border: 1px solid $attributeColor;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  color: #222;
  text-align: center;
  background: #fff;
}

.react-flow__node-attribute .react-flow__handle {
  background: $attributeColor;
}

.react-flow__node-attribute.selected,
.react-flow__node-attribute.selected:hover {
  box-shadow: 0 0 0 0.5px $attributeColor;
}

.react-flow__node-attribute.selectable:hover {
  box-shadow: 0 1px 4px 1px rgba(black,  0.08);
}

.react-flow__node-collectionKey {
  border: 1px solid $attributeKeyColor;
  padding: 10px;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  color: #222;
  text-align: center;
  background: #fff;
}

.react-flow__node-collectionKey .react-flow__handle {
  background: $attributeKeyColor;
}

.react-flow__node-collectionKey.selected,
.react-flow__node-collectionKey.selected:hover {
  box-shadow: 0 0 0 0.5px $attributeKeyColor;
}

.react-flow__node-collectionKey.selectable:hover {
  box-shadow: 0 1px 4px 1px rgba(black,  0.08);
}

// Actions
.react-flow__node-join,
.react-flow__node-lower,
.react-flow__node-upper {
  border: 1px solid $actionColor;
  padding: 10px;
  background: #fff;
  min-width: 6rem;
}

.react-flow__node-join  .react-flow__handle, 
.react-flow__node-upper .react-flow__handle,
.react-flow__node-lower .react-flow__handle {
  background: $actionColor;
}
.react-flow__node-lower .react-flow__handle-left::after {
  left: 5px;
}

.react-flow__node-join.selected,
.react-flow__node-upper.selected,
.react-flow__node-lower.selected {
  box-shadow: 0 0 0 0.5px $actionColor;
}

.react-flow__node-lower.selectable:hover,
.react-flow__node-join.selectable:hover,
.react-flow__node-upper.selectable:hover {
  box-shadow: 0 1px 4px 1px rgba(black,  0.08);
}